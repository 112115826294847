.parallax-container::-webkit-scrollbar-thumb,
.parallax-container::-webkit-scrollbar-thumb:hover {
  display: none;
}

.parallax-container::-webkit-scrollbar-track {
  display: none;
}

.parallax-container::-webkit-scrollbar {
  width: 0px;
}
