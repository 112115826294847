@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-Thin.woff") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./fonts/Jost/Jost-ThinItalic.woff") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-Light.woff") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./fonts/Jost/Jost-LightItalic.woff") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost.woff") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-Italic.woff") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-Medium.woff") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./fonts/Jost/Jost-MediumItalic.woff") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-SemiBold.woff") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./fonts/Jost/Jost-SemiBoldItalic.woff") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"), url("./fonts/Jost/Jost-Bold.woff") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Jost";
  src: local("Jost"),
    url("./fonts/Jost/Jost-BoldItalic.woff") format("truetype");
  font-weight: 700;
  font-style: italic;
}

body {
  margin: 0;
  font-family: "Jost", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  overflow-y: overlay;
}

.Typewriter {
  display: inline;
}
